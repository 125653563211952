import axios from "axios"
import { ApiError, pavukApi, semanticApi, twentyFourHoursInMs } from "./common"
import _ from 'lodash'
import { UseQueryOptions, useInfiniteQuery, useQuery } from "react-query"

// @ts-ignore
import io from "socket.io-client"

export interface Flow {
    id: string
    title: string
    shortDescription: string
    images: string
    logo: string
    inputModel?:any

    metadata: {
        stages: { label: string, icon: string, callback: string }[],
        inputs: { type: string, description: string }[]
        model: {
            url: string, Q_ID: string, buttonName: string,
            regexRule: string | null,
            isBeta?: boolean
            exampleInfo: {
                exampleUrl: string,
                exampleUrlPlaceholder: string,
                exampleUrlName: string,
                exampleText: string
            }
        } | null
    }
}

const getFlows = async (): Promise<Flow[]> => {
    const { data } = await axios.get<Flow[]>(`${pavukApi}/v1/flows`)
    return data ?? []
}

export const useFlows = (options?: UseQueryOptions<Flow[], ApiError>) => {
    const defaults =
    {
        staleTime: twentyFourHoursInMs,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        retry: true
    }

    return useQuery<Flow[], ApiError>('flows', getFlows, { ...options, ...defaults })
}

const getFlow = async (flowId: string): Promise<Flow> => {
    const { data } = await axios.get<Flow>(`${pavukApi}/v1/flows/${flowId}`)
    return data
}

export const useFlow = (flowId: string, options?: UseQueryOptions<Flow, ApiError>) => {
    const defaults =
    {
        staleTime: twentyFourHoursInMs,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        retry: true
    }

    return useQuery<Flow, ApiError>(['flow', flowId], () => getFlow(flowId), { ...options, ...defaults })
}

export interface FlowSession {
    sessionId: string
    name: string
    start: number
    finish: number
    flowId: string
    room: string
    link: string
    title: string
    fileName: string
    fileContent: string
    count: number
    error: string
}

export interface IRunFlowCustomParams {
    flowId: string
    flowLink: string
    documentAgeLimit?: number | null
    // recordsLimit?: number | null
}

export const runFlow = async ({ flowId, flowLink, documentAgeLimit }: IRunFlowCustomParams): Promise<FlowSession> => {
    const { data } = await axios.post<FlowSession>(`${pavukApi}/v1/flows/${flowId}/session`, {
        flowLink: flowLink,
        documentAgeLimit: documentAgeLimit ?? null,
        // recordsLimit: recordsLimit ?? null
    })

    return data
}

const getSession = async (sessionId: string): Promise<FlowSession> => {
    const { data } = await axios.get<FlowSession>(`${pavukApi}/v1/sessions/${sessionId}`)
    return data
}

export const useFlowSession = (sessionId: string, options?: UseQueryOptions<FlowSession, ApiError>) => {
    return useQuery<FlowSession, ApiError>(['session', sessionId], () => getSession(sessionId), options)
}

export interface FlowsArchive {
    archive: FlowSession[]
    next: string | null
}

export const getArchive = async (
    from: number | null,
    to: number | null,
    pageParam?: string | null | undefined
): Promise<FlowsArchive> => {
    if (from && from.toString().length >= 13) {
        from /= 1000;
    }
    if (to && to.toString().length >= 13) {
        to /= 1000;
    }

    let rq = pageParam ? pageParam : `/v1/sessions/archive/from/${from}/to/${to}/page/1`

    const { data } = await axios.get<FlowsArchive>(`${pavukApi}${rq}`)
    return data
}

export const useArchive = ({
    from,
    to,
    autorefresh,
    hts
}: {
    from: number | null,
    to: number | null,
    autorefresh: boolean,
    hts: number
}) => {
    return useInfiniteQuery(
        ['archive', hts],
        async ({ pageParam }) => {
            return await getArchive(from, to, pageParam)
        },
        {
            getNextPageParam: (lastPage) => {
                return lastPage.next
            },
            select: (data) => data,
            refetchOnWindowFocus: false,
            refetchInterval: autorefresh ? 10000 : twentyFourHoursInMs
        }
    )
}

export interface FlowProgressUpdate {
    data: { status: string, stage: string, data: { data: string, fileName: string, count: number } | null, errorMessage: string }
    marker: string
}

export class FlowSocketConnection {
    constructor(sessionId: string) {
        this.__callback = (data) => {
            console.dir(data)
        }

        this.__sessionId = sessionId

        if (!(document as any).socket_connections) {
            (document as any).socket_connections = {

            }
        }
    }

    private __callback: (data: any) => void;
    private __sessionId: string;

    setCallback = (func: (data: any) => void) => {
        this.__callback = func
    }

    syncData = (socket: any, sessionId: string, room: string, data: FlowProgressUpdate) => {
        this.__callback(data)

        if (data?.data?.stage === 'finish') {
            this.closeSocketConnection(room)
            socket.close()
        }
    }

    openSocketConnection = (room: string) => {
        if ((document as any).socket_connections[room]) {
            return (document as any).socket_connections[room]
        }

        const endpoint = `https://flow-api.semanticforce.ai`

        //connect to backend socket
        if (room) {
            const socketConnection = io(endpoint)

            socketConnection.on("connect_error", (error: any) => {
                console.log("Error connecting to " + endpoint, error)
            })
            socketConnection.on("connect", () => {
                // Connected, let's sign-up for to receive messages for this room
                socketConnection.emit("room", room)
            })

            socketConnection.on("sync", (data: any) => {
                console.log('Data received')
                this.syncData(socketConnection, this.__sessionId, room, data)
            });

            (document as any).socket_connections[room] = socketConnection;

            return socketConnection
        }

        return null
    }

    closeSocketConnection = (room: string) => {
        const connection = (document as any).socket_connections[room]
        if (connection) {
            (document as any).socket_connections[room] = undefined
        }
    }
}

export const useFlowSocketConnection = (sessionId: string, room: string, callback: (data: FlowProgressUpdate) => void) => {
    const doc = document as any
    if (!doc.socket_managers) {
        doc.socket_managers = {}
    }

    if (!doc.socket_managers[room]) {
        const connection = new FlowSocketConnection(sessionId)
        connection.setCallback(callback)
        connection.openSocketConnection(room)
        doc.socket_managers[room] = connection
    }

    return doc.socket_managers[room] as FlowSocketConnection
}